import gql from 'graphql-tag';

export const NEWS_LIST = gql`
    query newsList($pageSize:Int $page:Int) {
        newsList(pageSize:$pageSize page:$page) {
            newspost_id
            is_active
            url_key
            publish_date
            title
            tags
            short_content
            image_thumb
        }
    }
`

export const LATEST_NEWS = gql`
    query latestNews{
        latestNews{
            title
            url_key
            is_active
            newspost_id
        }
    }
`

export const NEWS_POST = gql`
    query newsPost($url_key:String!) {
        newsPost(url_key:$url_key){
            title
            publish_date
            image
            url_key
            image
            content
            meta_title
            meta_description
        }
    }
`

export const NEWS_PAGES = gql`
    query {
        newsPages
    }
`