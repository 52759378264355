/* eslint-disable camelcase */
import { useQuery } from '@apollo/react-hooks';
import { I18nContext, T } from '@deity-io/falcon-i18n';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LATEST_NEWS } from '../newsQuery';
import './NewsSidebar.scss';

const NewsSidebar = () => {
  const { t } = useContext(I18nContext);
  const { data, error, loading } = useQuery(LATEST_NEWS);

  if (error) {
    console.log('Error: ', error.message);
    return <h2>Error occured while loading data.</h2>;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="news-sidebar">
      <h3 className="news-sidebar__title">
        <T id="news.latestNewsPosts" />
      </h3>

      {data &&
        data.latestNews
          .filter(({ is_active }) => is_active)
          .map(({ title, url_key, newspost_id }) => (
            <ul key={newspost_id} className="news-sidebar__latest-news">
              {/* <hr /> */}
              <li>
                <Link key={title} to={`/${t('news.newsLink')}/${url_key}`}>
                  {title}
                </Link>
              </li>
            </ul>
          ))}
    </div>
  );
};

export default NewsSidebar;
