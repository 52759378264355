/* eslint-disable camelcase */
import { I18n, T } from '@deity-io/falcon-i18n';
import React from 'react';
import { Link } from 'react-router-dom';
import './NewsItem.scss';

const NewsItem = ({ title, short_post, date, url, image_src, is_active }) => {
  if (!is_active) {
    return null;
  }

  return (
    <div className="news-item">
      <I18n>
        {t => (
          <>
            <Link to={`${t('news.newsLink')}/${url}`}>
              <h5 className="news-item__title">{title}</h5>
            </Link>

            <div className="news-item__mid">
              {image_src && image_src !== 'false' && (
                <div className="news-item__mid__image">
                  <Link to={`${t('news.newsLink')}/${url}`}>
                    <img src={image_src} alt="" />
                  </Link>
                </div>
              )}

              <div className="news-item__mid__description">
                <div
                  className="news-item__short-post"
                  dangerouslySetInnerHTML={{
                    __html: short_post,
                  }}
                />

                <span className="news-item__date">
                  <T id="blog.published" />
                  {date}
                </span>
              </div>
            </div>

            <hr className="news-item__hr" />

            <div className="news-item__button-div">
              <Link to={`${t('news.newsLink')}/${url}`} className="news-item__button">
                <T id="blog.continue" />
              </Link>
            </div>
          </>
        )}
      </I18n>
    </div>
  );
};

export default NewsItem;
