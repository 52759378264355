/* eslint-disable camelcase */
import { useQuery } from '@apollo/react-hooks';
import { T } from '@deity-io/falcon-i18n';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Loader } from '@deity-io/falcon-ecommerce-uikit';
import './News.scss';
import NewsItem from './NewsItem/NewsItem';
import { NEWS_LIST, NEWS_PAGES } from './newsQuery';
import NewsSidebar from './NewsSidebar/NewsSidebar';

const News = () => {
  const [itemOffset, setItemOffset] = useState();
  const { data, error, loading } = useQuery(NEWS_LIST, {
    variables: {
      page: itemOffset,
    },
  });

  const { data: newsPages } = useQuery(NEWS_PAGES);

  if (error) {
    console.log('Error: ', error.message);
    return <h1>Error with data loading occured!</h1>;
  }

  if (!loading && !data) {
    console.log('no data');
  }

  const handlePageClick = event => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  return (
    <div className="news">
      <main className="news__main-content">
        <div className="news__page-title">
          <h1>
            <T id="news.newsPosts" />
          </h1>
        </div>

        <div className="news__column">
          {loading && <Loader />}

          <div className="news__column-main">
            {data &&
              data.newsList.map(
                ({ title, short_content, publish_date, url_key, image_thumb, newspost_id, is_active }) => (
                  <NewsItem
                    key={newspost_id}
                    title={title}
                    short_post={short_content}
                    date={publish_date}
                    url={url_key}
                    image_src={image_thumb}
                    is_active={is_active}
                  />
                ),
              )}
          </div>

          <NewsSidebar />
        </div>

        <div className="news__pagination">
          <ReactPaginate
            previousLabel={<div className="pagination__arrow_prev" />}
            nextLabel={<div className="pagination__arrow_next" />}
            forcePage={0}
            breakClassName="pagination__page"
            pageCount={newsPages ? newsPages.newsPages : 50}
            marginPagesDisplayed={-1}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            disabledClassName="disabled"
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            previousClassName="pagination__arrow"
            previousLinkClassName="pagination__arrow-link"
            nextClassName="pagination__arrow"
            nextLinkClassName="pagination__arrow-link"
            pageClassName="pagination__page"
            activeClassName="active-page"
            activeLinkClassName="active-link"
          />
        </div>
      </main>
    </div>
  );
};

export default News;
